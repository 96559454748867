import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {SnackbarProvider} from "notistack";

import {AuthContextProvider} from "./auth/context";
import {LoginPage} from "./auth/login-page";
import {ResourcesPage} from "./resource/resources-page";
import reportWebVitals from "./reportWebVitals";
import CredentialsPage from "./auth/credentials-page";
import AboutPage from "./about/about-page";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        {/* @ts-ignore */}
        <SnackbarProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/credentials" element={<CredentialsPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/" element={<ResourcesPage />} />
          </Routes>
        </SnackbarProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
