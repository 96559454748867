export const allAges = ["3-7 ans", "8-12 ans", "13-16 ans", "16 ans et+"];

export type Resource = {
  id: number;
  title: string;
  theme: string;
  nature: string;
  author: string;
  copyright: boolean;
  page: string | null;
  link?: string | null;
  discipline: string | null;
  age: Array<string>;
};
