import {FC, useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconSave from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useSnackbar} from "notistack";

import {useRequest} from "../request";
import {allAges, Resource} from "./resource-entity";
import Autocomplete from "./autocomplete-field";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {useAuth} from "../auth/context";

type EditResourceDialogHookState = {
  open: (resource?: Resource) => void;
  close: () => void;
  state: {
    open: boolean;
    resource?: Resource;
  };
};

export type EditResourceDialogProps = EditResourceDialogHookState & {
  onSave: () => Promise<void>;
};

export const EditResourceDialog: FC<EditResourceDialogProps> = props => {
  const request = useRequest();
  const snackbar = useSnackbar();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const createMode = props.state.resource === undefined;
  const close = loading ? () => {} : props.close;

  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [nature, setNature] = useState("");
  const [author, setAuthor] = useState("");
  const [page, setPage] = useState<string | null>("");
  const [mediaType, setMediaType] = useState<"url" | "file">("url");
  const [url, setUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [copyright, setCopyright] = useState(false);
  const [discipline, setDiscipline] = useState<string | null>(null);
  const [age, setAge] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.state.resource) {
      setTitle(props.state.resource.title);
      setTheme(props.state.resource.theme);
      setNature(props.state.resource.nature);
      setAuthor(props.state.resource.author);
      setPage(props.state.resource.page);
      setCopyright(props.state.resource.copyright);
      setDiscipline(props.state.resource.discipline);
      setAge(props.state.resource.age);
      if (props.state.resource.link) {
        if (props.state.resource.link.startsWith("http")) {
          setMediaType("url");
          setUrl(props.state.resource.link);
        } else {
          setMediaType("file");
          setFile(new File([], props.state.resource.link));
        }
      } else {
        setMediaType("url");
      }
    }

    return () => {
      setTitle("");
      setTheme("");
      setNature("");
      setAuthor("");
      setPage(null);
      setMediaType("url");
      setUrl(null);
      setFile(null);
      setCopyright(false);
      setDiscipline(null);
      setAge([]);
    };
  }, [props.state.resource]);

  async function save() {
    if (loading) return;
    setLoading(true);
    let link;

    if (mediaType === "file" && file) {
      if (file.size > 0) {
        const body = new FormData();
        body.append("file", file);
        const res = await fetch(`${process.env.REACT_APP_FTP_URI}`, {
          method: "POST",
          body,
        });
        link = await res.text();
      } else {
        link = file.name;
      }
    } else if (mediaType === "url") {
      link = url;
    }

    const resource: Omit<Resource, "id"> = {
      title,
      theme,
      nature,
      author,
      page,
      link,
      copyright,
      discipline,
      age,
    };

    const save =
      props.state.resource === undefined
        ? request.post(`/resources?columns=${Object.keys(resource)}`, {}, resource)
        : request.put(`/resources?id=eq.${props.state.resource.id}`, {}, {...resource, id: props.state.resource.id});

    save
      .then(props.onSave)
      .then(() => {
        snackbar.enqueueSnackbar(`Ressource ${createMode ? "ajoutée" : "modifiée"} avec succès`, {
          autoHideDuration: 4000,
          variant: "success",
        });
        props.close();
      })
      .finally(() => setLoading(false));
  }

  return (
    <Dialog open={props.state.open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{createMode ? "Ajouter" : "Modifier"} une ressource</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{mt: 1}}>
          <TextField
            autoFocus
            required
            label="Titre"
            size="small"
            disabled={loading}
            value={title}
            onChange={evt => setTitle(evt.target.value)}
          />
          <Autocomplete required name="theme" label="Thème" value={theme} onChange={setTheme} disabled={loading} />
          <Autocomplete required name="nature" label="Nature" value={nature} onChange={setNature} disabled={loading} />
          <Autocomplete required name="author" label="Auteur" value={author} onChange={setAuthor} disabled={loading} />
          <TextField
            required
            label="Page"
            size="small"
            disabled={loading}
            value={page || ""}
            onChange={evt => setPage(evt.target.value)}
          />
          <FormControl size="small" disabled={loading} sx={{alignItems: "flex-start"}}>
            <InputLabel id="age" disabled={loading}>
              Âge
            </InputLabel>
            <Select
              size="small"
              labelId="age"
              disabled={loading}
              multiple
              value={age}
              onChange={evt =>
                setAge(typeof evt.target.value === "string" ? evt.target.value.split(",") : evt.target.value)
              }
              input={<OutlinedInput label="Âge" fullWidth disabled={loading} />}
              renderValue={v => v.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {allAges.map(a => (
                <MenuItem key={a} value={a}>
                  <Checkbox checked={age.includes(a)} />
                  <ListItemText primary={a} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Discipline scolaire"
            size="small"
            disabled={loading}
            value={discipline || ""}
            onChange={evt => setDiscipline(evt.target.value.trim() || null)}
          />
          <FormControl>
            <FormLabel required>Ressource</FormLabel>
            <RadioGroup value={mediaType} onChange={evt => setMediaType(evt.target.value === "file" ? "file" : "url")}>
              <FormControlLabel
                value="url"
                control={<Radio />}
                componentsProps={{
                  typography: {
                    width: "100%",
                  },
                }}
                label={
                  <TextField
                    onClick={() => setMediaType("url")}
                    label="Lien web"
                    size="small"
                    disabled={loading || mediaType === "file"}
                    value={url || ""}
                    onChange={evt => setUrl(evt.target.value)}
                    fullWidth
                  />
                }
              />
              <FormControlLabel
                value="file"
                control={<Radio />}
                componentsProps={{
                  typography: {
                    width: "100%",
                  },
                }}
                label={
                  <>
                    {file && <FormLabel>{file.name}</FormLabel>}
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                      fullWidth
                      disabled={loading || mediaType === "url"}
                    >
                      Télécharger un fichier
                      <input
                        type="file"
                        hidden
                        onChange={evt => setFile(evt.target.files ? evt.target.files[0] : null)}
                      />
                    </Button>
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
          {["admin"].includes(auth.role) && (
            <FormControlLabel
              control={
                <Switch
                  id="copyright"
                  disabled={loading}
                  checked={copyright}
                  onChange={evt => setCopyright(evt.target.checked)}
                />
              }
              label="Droits d'auteur"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {!loading && <Button onClick={close}>Annuler</Button>}
        <LoadingButton
          variant="contained"
          loadingPosition="start"
          loading={loading}
          disabled={loading}
          onClick={save}
          startIcon={<IconSave />}
        >
          Enregistrer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export function useEditResourceDialog(): EditResourceDialogHookState {
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState<Resource>();

  return {
    open: resource => {
      setResource(resource);
      setOpen(true);
    },
    close: () => {
      setResource(undefined);
      setOpen(false);
    },
    state: {
      open,
      resource,
    },
  };
}

export default EditResourceDialog;
