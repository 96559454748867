import {FC} from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import AppBar from "../app-bar";
import Footer from "../footer";

export const AboutPage: FC = () => {
  return (
    <>
      <AppBar />
      <Container maxWidth="lg">
        <Paper variant="outlined" sx={{padding: 2, marginY: 4}}>
          <div style={{maxWidth: "49em", padding: "2em", textAlign: "justify", margin: "auto"}}>
            <div style={{textAlign: "center", margin: "0 auto 5em auto"}}>
              <img src="/static/media/logo.fd9a6f8c946142de2b78a1f9d4ccdd46.svg" className="App-logo" alt="logo" />
            </div>

            <strong>
              Le balluchon de l’animateur.trice a pour ambition de soutenir et développer la pratique des
              animateur.trices de dialogues philosophiques.
            </strong>

            <p>
              Cette base de données est destinée à tous les animateurs.trices de dialogue philosophique. Elle recense
              les ressources proposées par les principaux sites connus de notre part liés à la pratique du dialogue
              philosophique. (Voir liste ci-dessous). Ainsi que le matériel créé par les animateur.trices de proPhilo.
              Ces ressources sont issues de sites francophones.
            </p>

            <p>
              Cette base recense également quelques 600 références aux guides pédagogiques de Mathew Lipman. Ces
              références sont citées mais ne sont pas accessibles, car sujettes au droit d’auteur.
            </p>

            <p>
              Son usage se veut collaboratif. Ainsi chacun.e est invité.e à partager ses ressources, qu’il s’agisse d’un
              plan de discussion, d’un atelier, d’un support, d’un exercice ou d’un apport théorique.
            </p>

            <p>
              Cette base est ouverte à tous.tes en mode lecture et à toute personne active dans la pratique du dialogue
              philosophique en mode editeur. Un guide d'utilisation est disponible au lien suivant:
            </p>
            <p>
              <a target="_blank" href="/proPhilo_Guide_utilisateur.pdf">
                → Guide utilisateur
              </a>
            </p>

            <p>Nous vous en souhaitons une belle découverte.</p>

            <p style={{textAlign: "right"}}>
              <em>proPhilo-Genève, 2022</em>
            </p>

            <h1 style={{borderTop: "1px dotted", paddingTop: "2em", marginTop: "2em"}}>
              Liste des sites dont sont issues certaines ressources de la base de données :
            </h1>

            <h3>En France</h3>
            <ul>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://penserouvrir.com/">Penser Ouvrir</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.pratiques-philosophiques.com/">Institut des pratiques philosophiques</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://philosophie-pedagogie.web.ac-grenoble.fr/">Académie de Grenoble</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="http://www.philo-pour-enfants.com/">Philo pour enfants</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://philogalichet.fr/">François Galichet</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://padlet.com/cbudex/pvp">Padlet de Christian Budex</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.charivarialecole.fr/archives/2539">Charivari, Pomme d’Api</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://padlet.com/chloe_boucher_re/k052aj33q7c5">Padlet de Cloe Bouchet</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="http://www.micheltozzi.com/">Site de Michel Tozzi</a>
              </li>
            </ul>

            <h3>En Belgique</h3>
            <ul>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.philocite.eu/">Philocité</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.calbw.be/pole-philo">PolePhilo</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.phileasetautobule.be/">Phileas et Autobule</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.entre-vues.net/">Entrevue, Pédagogie de la philosophie et de la citoyenneté</a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://www.pratiques-philosophiques.com/">Diotime</a>
              </li>
            </ul>

            <h3>Au Quebec</h3>
            <ul>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://philoenfant.org/author/michelsasseville/">
                  Université de Laval, site de Michel Sasseville
                </a>
              </li>
              <li style={{padding: "0.22em 0"}}>
                <a href="https://ipcj.umontreal.ca/accueil/">Brila Montreal</a>
              </li>
            </ul>

            <h1 style={{borderTop: "1px dotted", paddingTop: "2em", marginTop: "2em"}}>Crédit développement:</h1>

            <p>
              <a href="http://tecfa.unige.ch/perso/dacosta">Julien DA COSTA</a>, Ingénieur Pédagogique
            </p>
            <p>
              <a href="https://soywod.me">Clément DOUIN</a>, Développeur Indépendant
            </p>
          </div>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default AboutPage;
