import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export type AuthState = typeof NOT_INITIALIZED | typeof NOT_AUTHENTICATED | Authenticated;

export const NOT_INITIALIZED = {
  type: "not-initialized" as const,
  role: "anon" as Role,
  token: "",
};

export const NOT_AUTHENTICATED = {
  type: "not-authenticated" as const,
  role: "anon" as Role,
  token: "",
};

type Authenticated = {
  type: "authenticated";
  login: string;
  role: Role;
  token: string;
};

type Role = "anon" | "restricted_reader" | "reader" | "restricted_editor" | "editor" | "admin";

type AuthContext = [AuthState, Dispatch<SetStateAction<AuthState>>];
const authContext = createContext<AuthContext>([NOT_INITIALIZED, () => {}]);

const LOCAL_STORAGE_KEY = "prophilo";

export const AuthContextProvider: FC = props => {
  const navigate = useNavigate();
  const value = useState<AuthState>(NOT_INITIALIZED);
  const [state, setState] = value;

  useEffect(() => {
    const auth = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (auth) {
      const {token, login, role} = JSON.parse(auth);
      fetch(`${process.env.REACT_APP_BACKEND_URI}/rpc/check_auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res.ok) {
            setState({type: "authenticated", token, login, role});
          } else {
            setState(NOT_AUTHENTICATED);
          }
        })
        .catch(() => {
          setState(NOT_AUTHENTICATED);
        });
    } else {
      setState(NOT_AUTHENTICATED);
    }
  }, [setState]);

  useEffect(() => {
    if (state.type === "not-authenticated") {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
    }
  }, [state.type, navigate]);

  return (
    <authContext.Provider value={value}>
      {state.type === "not-initialized" ? (
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
          <CircularProgress size={64} thickness={4} />
        </Box>
      ) : (
        props.children
      )}
    </authContext.Provider>
  );
};

export function useAuth() {
  return useContext(authContext);
}
