import {FC} from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconLogout from "@mui/icons-material/Logout";
import IconCredentials from "@mui/icons-material/Key";
import {useNavigate} from "react-router-dom";

import {NOT_AUTHENTICATED, useAuth} from "./auth/context";
import {ReactComponent as Logo} from "./logo.svg";

export const AppBar: FC = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{display: {xs: "none", sm: "block"}}}
          onClick={() => navigate("/")}
          style={{cursor: "pointer"}}
        >
          <Logo height="3rem" />
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{display: {xs: "none", sm: "block"}}}
          onClick={() => navigate("/")}
        >
          <h1>Le Balluchon de l'animateur·trice</h1>
        </Typography>
        <Box sx={{flexGrow: 1}} />
        {auth.role === "admin" && (
          <IconButton color="inherit" title="Gérer les mots de passe" onClick={() => navigate("/credentials")}>
            <IconCredentials />
          </IconButton>
        )}
        {auth.type === "authenticated" && (
          <Button
            color="inherit"
            title="Se déconnecter"
            onClick={() => setAuth(NOT_AUTHENTICATED)}
            endIcon={<IconLogout />}
          >
            {auth.login}
          </Button>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
