import {FC} from "react";
import {Link} from "react-router-dom";

export const Footer: FC = () => {
  return (
    <div id="footer">
      <p style={{textAlign: "center", color: "white"}}>
        Prophilo.ch © 2022 |{" "}
        <Link to="/about" style={{color: "white"}}>
          À propos
        </Link>
      </p>
    </div>
  );
};

export default Footer;
