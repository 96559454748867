import {FC, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconFileOpen from "@mui/icons-material/FileOpen";
import IconFile from "@mui/icons-material/InsertDriveFile";
import IconLink from "@mui/icons-material/Link";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconAdd from "@mui/icons-material/AddCircleTwoTone";
import IconButton from "@mui/material/IconButton";
import IconDelete from "@mui/icons-material/DeleteTwoTone";
import IconEdit from "@mui/icons-material/EditTwoTone";
import IconSearch from "@mui/icons-material/Search";
import IconClose from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {GridRowId} from "@mui/x-data-grid";
import mime from "mime";

import {useAuth} from "../auth/context";
import {ResponseWithData, useRequest} from "../request";
import EditResourceDialog, {useEditResourceDialog} from "./edit-resource-dialog";
import Autocomplete from "./autocomplete-field";
import {allAges, Resource} from "./resource-entity";
import {useSnackbar} from "notistack";
import AppBar from "../app-bar";
import Footer from "../footer";
import {InputAdornment} from "@mui/material";

function toQueryParams(params: Record<string, string | string[]>) {
  return Object.keys(params)
    .filter(key => {
      const val = params[key];
      return typeof val === "string" ? val.trim() !== "" : val.length > 0;
    })
    .map(key => {
      let val = params[key];
      val =
        typeof val === "string"
          ? `ilike.*${encodeURIComponent(val)}*`
          : `cs.{${val.map(v => `"${encodeURIComponent(v)}"`)}}`;
      return `${key}=${val}`;
    })
    .join("&");
}

const PAGE_SIZE = 10;

export type ResourcesPageProps = {};

export const ResourcesPage: FC<ResourcesPageProps> = () => {
  const request = useRequest();
  const snackbar = useSnackbar();
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>();
  const [resourcesCount, setResourcesCount] = useState(0);
  const [theme, setTheme] = useState("");
  const [nature, setNature] = useState("");
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [age, setAge] = useState<string[]>([]);
  const [discipline, setDiscipline] = useState("");
  const [resourceDeletionDialogOpen, openResourceDeletionDialog] = useState<GridRowId[]>([]);
  const [page, setPage] = useState(0);
  const editResourceDialog = useEditResourceDialog();

  useEffect(() => {
    refreshResources();
  }, []);

  async function readResources(page: number): Promise<ResponseWithData<Resource[]>> {
    const table = ["restricted_reader", "restricted_editor"].includes(auth.role) ? "restricted_resources" : "resources";
    const where = toQueryParams({theme, nature, author, title, age, discipline});
    const order = "order=theme.asc";
    const limit = `limit=${PAGE_SIZE}`;
    const offset = `offset=${page * PAGE_SIZE}`;
    const query = [where, order, limit, offset].join("&");
    return request.get<Resource[]>(`/${table}?${query}`, {prefer: "count=exact"});
  }

  async function refreshResources(page = 0): Promise<void> {
    if (loading) return;
    setLoading(true);
    setPage(page);
    readResources(page)
      .then(res => {
        const contentRange = res.headers.get("Content-Range") || "";
        const resourcesCount = Number(contentRange.split("/")[1] || "0");
        setResourcesCount(resourcesCount);
        setResources(res.data);
      })
      .finally(() => setLoading(false));
  }

  function refreshResourcesOnEnter(evt: React.KeyboardEvent): void {
    if (evt.key === "Enter") {
      evt.preventDefault();
      refreshResources();
    }
  }

  async function deleteResources(ids: GridRowId[]): Promise<void> {
    if (loading) return;
    setLoading(true);
    openResourceDeletionDialog([]);
    request
      .delete(`/resources?id=in.(${ids.join(",")})`)
      .then(() => readResources(page))
      .then(res => setResources(res.data))
      .then(() =>
        snackbar.enqueueSnackbar(`Ressource supprimée avec succès`, {
          autoHideDuration: 4000,
          variant: "success",
        }),
      )
      .finally(() => setLoading(false));
  }

  async function downloadFile(path: string) {
    // NOTE: uses URL.pathname to get rid of old pathnames containing
    // dots like "./Data-Lipman/Pixie.pdf"
    const encodedPath = encodeURIComponent(new URL(path, "https://balluchon-prophilo.ch").pathname);
    const res = await fetch(process.env.REACT_APP_FTP_URI + "/" + encodedPath);
    const blob = new Blob([await res.blob()], {type: mime.getType(path) || "application/octet-stream"});
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  }

  return (
    <>
      <AppBar />
      <Container maxWidth="lg">
        <Paper variant="outlined" sx={{padding: 2, marginY: 4}}>
          <Typography variant="h6" component="h2" gutterBottom>
            <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
              <IconSearch />
              <span>Filtres de recherche</span>
              <Box sx={{flexGrow: 1}} />
              <LoadingButton
                size="small"
                variant="contained"
                color="primary"
                onClick={() => refreshResources()}
                loading={loading}
                loadingPosition="start"
                startIcon={<IconSearch />}
              >
                Rechercher
              </LoadingButton>
            </Stack>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="Titre"
                margin="dense"
                size="small"
                fullWidth
                value={title}
                onChange={evt => setTitle(evt.target.value)}
                onKeyDown={refreshResourcesOnEnter}
                InputProps={{
                  endAdornment: title ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setTitle("")} edge="end" size="small">
                        <IconClose fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete clearable name="theme" label="Thème" value={theme} onChange={setTheme} />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete clearable name="nature" label="Nature" value={nature} onChange={setNature} />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete clearable name="author" label="Auteur" value={author} onChange={setAuthor} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{margin: "auto 0"}}>
              <FormControl size="small" disabled={loading} sx={{alignItems: "center"}} fullWidth>
                <InputLabel id="age" disabled={loading}>
                  Âge
                </InputLabel>
                <Select
                  size="small"
                  labelId="age"
                  disabled={loading}
                  multiple
                  value={age}
                  onChange={evt =>
                    setAge(typeof evt.target.value === "string" ? evt.target.value.split(",") : evt.target.value)
                  }
                  input={<OutlinedInput label="Âge" fullWidth disabled={loading} />}
                  renderValue={v => v.join(", ")}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                >
                  {allAges.map(a => (
                    <MenuItem key={a} value={a}>
                      <Checkbox checked={age.includes(a)} />
                      <ListItemText primary={a} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Discipline"
                margin="dense"
                size="small"
                fullWidth
                value={discipline}
                onChange={evt => setDiscipline(evt.target.value)}
                onKeyDown={refreshResourcesOnEnter}
              />
            </Grid>
          </Grid>
        </Paper>

        {resources && (
          <Paper variant="outlined" sx={{mb: 2}}>
            <TableContainer>
              <Table size="small">
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell>Titre</TableCell>
                    <TableCell>Thème</TableCell>
                    <TableCell>Nature</TableCell>
                    <TableCell>Auteur</TableCell>
                    <TableCell>Discipline</TableCell>
                    <TableCell>Âge</TableCell>
                    <TableCell align="center">Page(s)</TableCell>
                    {["restricted_editor", "editor", "admin"].includes(auth.role) && (
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          color="success"
                          title="Ajouter"
                          onClick={() => editResourceDialog.open()}
                        >
                          <IconAdd />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resources.map(resource => (
                    <TableRow key={resource.id} style={{height: 50}}>
                      <TableCell>
                        {["restricted_reader", "restricted_editor"].includes(auth.role) && resource.copyright ? (
                          <span>
                            <IconFile className="docIcon" />
                            {resource.title}
                          </span>
                        ) : resource.link && resource.link.startsWith("http") ? (
                          <Link href={resource.link} title={resource.link} target="_blank" style={{cursor: "pointer"}}>
                            <IconLink className="docIcon" color="primary" />
                            {resource.title}
                          </Link>
                        ) : (
                          <Link
                            onClick={() => resource.link && downloadFile(resource.link)}
                            title={resource.link || ""}
                            style={{cursor: "pointer"}}
                          >
                            <IconFileOpen className="docIcon" color="primary" />
                            {resource.title}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{resource.theme}</TableCell>
                      <TableCell>{resource.nature}</TableCell>
                      <TableCell>{resource.author}</TableCell>
                      <TableCell>{resource.discipline}</TableCell>
                      <TableCell>{resource.age && resource.age.join(", ")}</TableCell>
                      <TableCell align="center">{resource.page}</TableCell>
                      {["restricted_editor", "editor", "admin"].includes(auth.role) && (
                        <TableCell align="center">
                          {(["admin"].includes(auth.role) || !resource.copyright) && (
                            <>
                              <IconButton
                                size="small"
                                title="Modifier"
                                onClick={() => editResourceDialog.open(resource)}
                              >
                                <IconEdit color="primary" />
                              </IconButton>
                              <IconButton
                                size="small"
                                title="Supprimer"
                                onClick={() => openResourceDeletionDialog([resource.id])}
                              >
                                <IconDelete color="error" />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[PAGE_SIZE]}
                      rowsPerPage={PAGE_SIZE}
                      count={resourcesCount}
                      page={page}
                      onPageChange={(_, page) => refreshResources(page)}
                      sx={{borderBottom: "none"}}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        )}
        <Dialog
          open={resourceDeletionDialogOpen.length > 0}
          onClose={() => openResourceDeletionDialog([])}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer
              {resourceDeletionDialogOpen.length < 2
                ? " cette ressource "
                : ` ${resourceDeletionDialogOpen.length} ressources `}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => openResourceDeletionDialog([])}>Non</Button>
            <Button onClick={() => deleteResources(resourceDeletionDialogOpen)}>Oui</Button>
          </DialogActions>
        </Dialog>
        <EditResourceDialog {...editResourceDialog} onSave={() => refreshResources(page)} />
      </Container>
      <Footer />
    </>
  );
};

export default ResourcesPage;
