import {FC, KeyboardEvent, useEffect, useState} from "react";
import {Buffer} from "buffer";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router";
import {useAuth} from "./context";
import logo from "../logo.svg";
import "./login-page.css";

export type LoginPageProps = {};

export const LoginPage: FC<LoginPageProps> = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, openSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  function handleLogin() {
    setLoading(true);

    fetch(`${process.env.REACT_APP_BACKEND_URI}/rpc/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({login, pass}),
    })
      .then(async res => [res.ok, await res.json()])
      .then(([ok, res]) => {
        if (ok) {
          const {token} = res[0];
          const jwtPayload = JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
          const payload = {token, login: jwtPayload.login, role: jwtPayload.role};
          localStorage.setItem("prophilo", JSON.stringify(payload));
          setAuth({type: "authenticated", ...payload});
        } else {
          setSnackbarMsg(res.message);
          openSnackbar(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loginIfEnter(evt: KeyboardEvent) {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleLogin();
    }
  }

  useEffect(() => {
    if (auth.type === "authenticated") {
      navigate("/");
    }
  }, [auth.type, navigate]);

  return (
    <>
      <Dialog open style={{background: "url(/prophilo_header.webp) top center no-repeat"}}>
        <img src={logo} className="App-logo" alt="logo" />
        <DialogTitle className="toolName">Le Balluchon de l'animateur·trice</DialogTitle>
        <DialogTitle>Connexion requise</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour accéder au panel d'administration de proPhilo Database, veuillez saisir votre identifiant et votre mot
            de passe :
          </DialogContentText>
          <TextField
            autoFocus
            id="login"
            label="Identifiant"
            margin="normal"
            fullWidth
            required
            value={login}
            onChange={evt => setLogin(evt.target.value)}
            onKeyDown={loginIfEnter}
          />
          <TextField
            id="pass"
            type="password"
            label="Mot de passe"
            margin="normal"
            fullWidth
            required
            value={pass}
            onChange={evt => setPass(evt.target.value)}
            onKeyDown={loginIfEnter}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} disabled={loading} onClick={handleLogin} variant="contained">
            Se connecter
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={(_, reason) => reason !== "clickaway" && openSnackbar(false)}
      >
        <Alert severity="error" variant="filled" onClose={() => openSnackbar(false)} sx={{width: "100%"}}>
          <AlertTitle>Erreur</AlertTitle>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginPage;
