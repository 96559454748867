import {useSnackbar} from "notistack";

import {useAuth} from "./auth/context";

type EnqueueSnackbar = (msg: string) => void;

export type ResponseWithData<T> = Response & {data: T};

export function request(method: string, token: string, enqueueSnackbar: EnqueueSnackbar) {
  return async <T = void>(
    path: string,
    headers: Record<string, any> = {},
    body?: Record<string, any>,
  ): Promise<ResponseWithData<T>> =>
    fetch(process.env.REACT_APP_BACKEND_URI + path, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(async res => {
        if (res.ok) {
          return Object.assign(res, {data: [200, 206].includes(res.status) ? await res.json() : undefined});
        } else {
          const {message = res.statusText} = await res.json();
          throw new Error(message);
        }
      })
      .catch(err => {
        enqueueSnackbar(err.message);
        throw err;
      });
}

export function useRequest() {
  const [auth] = useAuth();
  const snackbar = useSnackbar();

  function enqueueErrorSnackbar(message: string): void {
    snackbar.enqueueSnackbar(message, {variant: "error", autoHideDuration: 4000});
  }

  return {
    get: request("GET", auth.token, enqueueErrorSnackbar),
    post: request("POST", auth.token, enqueueErrorSnackbar),
    put: request("PUT", auth.token, enqueueErrorSnackbar),
    delete: request("DELETE", auth.token, enqueueErrorSnackbar),
  };
}
