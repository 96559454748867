import {FC, useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import {useRequest} from "../request";
import {Resource} from "./resource-entity";

export type AutocompleteFieldProps = {
  name: keyof Resource;
  label: string;
  value: string;
  onChange: (value: string) => void;
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
};

export const AutocompleteField: FC<AutocompleteFieldProps> = props => {
  const [options, setOptions] = useState<Array<string>>();
  const request = useRequest();
  const loading = options === undefined;

  useEffect(() => {
    request
      .get<Array<Pick<Resource, typeof props.name>>>(`/resources?select=${props.name}`)
      .then(res =>
        setOptions(
          [
            ...new Set(
              res.data
                .map(resource => resource[props.name])
                .filter(Boolean)
                .map(String),
            ),
          ].sort((a, b) => a.localeCompare(b)),
        ),
      )
      .catch(() => setOptions([]));
  }, []);

  return (
    <Autocomplete
      freeSolo
      size="small"
      disableClearable={!props.clearable}
      disabled={props.disabled}
      options={loading ? ["Chargement…"] : options}
      inputValue={props.value}
      onInputChange={(_, val) => props.onChange(val)}
      renderInput={inputProps => (
        <TextField {...inputProps} label={props.label} margin="dense" required={props.required} />
      )}
    />
  );
};

export default AutocompleteField;
