import {FC, useState} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import {useRequest} from "../request";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import AppBar from "../app-bar";
import {IconButton, InputAdornment} from "@mui/material";

const labels: Record<string, string> = {
  restricted_reader: "Lecteur",
  reader: "Lecteur+",
  restricted_editor: "Éditeur",
  editor: "Éditeur+",
};

export type CredentialsPageProps = {};

export const CredentialsPage: FC<CredentialsPageProps> = () => {
  const request = useRequest();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [passwds, setPasswds] = useState<Record<string, string>>({});

  function updatePass(role: string) {
    return () => {
      if (loading) return;
      setLoading(true);
      request
        .post("/rpc/update_pass", {}, {r: role, p: passwds[role]})
        .then(() => {
          snackbar.enqueueSnackbar(`Mot de passe modifié avec succès`, {
            autoHideDuration: 4000,
            variant: "success",
          });
          setPasswds(p => ({...p, [role]: ""}));
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }

  return (
    <>
      <AppBar />
      <Container maxWidth="lg">
        <Paper variant="outlined" sx={{padding: 2, marginY: 4}}>
          <Typography variant="h6" component="h1" gutterBottom>
            Gestion des mots de passe
          </Typography>
          {["restricted_reader", "reader", "restricted_editor", "editor"].map(role => (
            <Stack key={role} direction="row" spacing={1} sx={{mt: 2}}>
              <TextField
                disabled={loading}
                fullWidth
                size="small"
                label={labels[role]}
                value={passwds[role]}
                onChange={evt => setPasswds(p => ({...p, [role]: evt.target.value}))}
              />
              <Button variant="contained" color="secondary" size="small" onClick={updatePass(role)} disabled={loading}>
                Changer
              </Button>
            </Stack>
          ))}
        </Paper>
      </Container>
    </>
  );
};

export default CredentialsPage;
